.base {
  text-align: left;
}

.info {
  background-color: #fff;
  border: .1rem solid #F6F5F5;
  border-radius: 5rem 1rem 1rem 1rem;
  padding: 4rem;
}

.info p {
  line-height: 2.7rem;
}

.title {
  display: flex;
  align-items: center;
}

.icon {
  border: .2rem solid #F9D72F;
  width: 4.6rem;
  height: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.icon svg path {
  fill: #F9D72F;
}

.text {
  margin-left: 1.6rem;
}

.carousel {
  padding: 6rem 0 0;
}