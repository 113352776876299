.base {
  background-color: #EAF8FC;
  padding: 2rem;
  border-radius: 1rem;
  color: #005DA4;
  line-height: 150%;
  animation: slideIn .2s cubic-bezier(.19,.58,.39,.89) forwards;
  height:auto;
  max-height:600px;
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}


.base.isError {
  background-color: #FAD6D9;
  color: #E7343F;
}

.base.isSuccess {
  background-color: #DDEEE0;
  color: #54A964;
}
