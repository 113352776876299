.base {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  padding: 2rem;
  text-align: center;
  position: relative;
}

.list {
   
}

.list > .base {
  margin: 3.2rem 0;
}
.list > .base:first-child {
  margin-top: 0;
}
.list > .base:last-child {
  margin-bottom: 0;
}

.list .list {
  display: flex;
  flex-flow: row wrap;
  margin: -1.5rem;
}

.list .list > .base {
  flex: 1 1 50%;
  margin: 1.5rem;
}