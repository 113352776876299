.base {
  width: 100%;
  border-collapse: collapse;
}

.base thead th {
  padding: 1.4rem 2rem;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  text-align: center;
  user-select: none;
  position: relative;
}
.base thead th:after {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #fff;
  width: 2rem;
  right: -1rem;
  top: 0;
  z-index: 1;
}

.base thead th:first-child {
  border-radius: 1rem 0 0 1rem;
}
.base thead th:last-child {
  border-radius: 0 1rem  1rem 0;
}
.base thead th:last-child:after {
  content: none;
}

.base tbody td {
  border-bottom: .1rem solid rgba(160,160,160,0.21);
  padding: 1.4rem 2rem;
  text-align: center;
}

.base thead th:nth-child(1),
.base thead th:nth-child(2),
.base tbody td:nth-child(1),
.base tbody td:nth-child(2) {
  text-align: left;
}

.icon {
  color: #E7343F;
}