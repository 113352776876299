.base {
  background-color: #F6F5F5;
  padding: 1.5rem 2rem;
  border-radius: .4rem;
  cursor: pointer;
  opacity: 1;
  transition: all .2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.base:hover {
  opacity: .8;
}

.label {
  padding-right: 1rem;
}

.base.isCorrect {
  background-color: #DDEEE0;
  color: #54A964;
}

.base.isCorrect svg {
  fill: #54A964;
}

.base.isWrong {
  background-color: #FAD6D9;
  color: #E7343F;
}

.base.isWrong svg {
  fill: #E7343F;
}
