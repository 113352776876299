.base {
  position: relative;
}

.button {
  width: 4.6rem;
  height: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  background-color: #fff;
  transition: all .2s;
  cursor: pointer;
}
.button:hover svg {
  fill: #fff;
}

.button:hover {
  color: #fff;
  background-color: #2AB4DB;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: -16%;
  z-index: 9999;
  padding-top: 2rem;
}