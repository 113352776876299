.base {
  padding: 2.4rem;
  min-height: 100vh;
  min-width: 102.4rem;
  /* background: url(../ui/bg01.png) center -4rem no-repeat; */
}

.container {
  /* max-width: 1280px; */
  min-width: 97.6rem;
  max-width: 102.4rem;
  margin: 0 auto;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  margin: 0 0 2rem;
  padding: 0 1.6rem;
}
.isLoggedIn .header {
  padding: 0;
}

.header > div {}

.logo {
  width: 100%;
  max-width: 72.8rem;
}

.account {
  position: relative;
  display: inline-block;
}

.accountName {
  font-weight: bold;
  margin-left: .5rem;
  cursor: pointer;
  color: #2AB4DB;
  transition: .2s;
}
.accountName:hover {
  color: #fff;
}

.accountTooltip {
  position: absolute;
  top: 100%;
  right: -16%;
  z-index: 9999;
  padding-top: 2rem;
}

.sidebar {
  color: #fff;
  text-align: right;
  flex: 1;
}
.isLoggedIn .sidebar {
  text-align: center;
}

.sidebar b {
  color: #2AB4DB;
  cursor: pointer;
}
.sidebar b:hover {
  color: #fff;
}

.base.isV2 .header {
  padding: 0 1.6rem;
}

.base.isV2 .sidebar {
  text-align: right;
}

.base.isV3 .sidebar {
  text-align: right;
  color: #005DA4;
}
.base.isV3 .sidebar b {
  color: #005DA4;
  cursor: pointer;
}
.base.isV3 .sidebar b:hover {
  color: #2AB4DB;
}