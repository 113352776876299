.base {
  max-height: 21rem;
  overflow-y: auto;
}

.code {
  padding: .5rem;
}

.link {
  margin: 2rem 0;
}