.base {
  margin: 2rem 0;
  padding: 0;
  text-align: left;
  color: #005DA4;
  list-style-type: none;
}

.base > li {
  display: flex;
  justify-content: space-between;
  border-bottom: .1rem solid #F6F5F5;
  padding: 1.4rem 0;
}

.base > li:last-child {
  border-bottom: 0;
}

.base > li svg {
  fill: #E1E1E1;
}

.base .text {
  flex-grow: 1;
}

.itemClick {
  cursor: pointer;
}

.base > li:global(.highlighted) {
  color: #54A964;
  background-color: #DDEEE0;
  border-radius: .4rem;
  border-bottom-color: #fff;
}

.base.hasIconPos-left .text {
  order: 2;
  text-align: left;
}
.base.hasIconPos-left .icon {
  order: 1;
  padding: .3rem 1.4rem 0;
}

.base.hasIconPos-left .icon svg {
  fill: #54A964;
}