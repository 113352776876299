.base {
  display: block;
  width: 100%;
}

.background,
.progress {
  fill: transparent;
}
.background {
  stroke: #F6F5F5;
}
.progress {
  fill: none;
  stroke: #54A964;
  transition: all 1.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.progress-25 {
  stroke: #E7343F;
}
.progress-50 {
  stroke: #F9D72F;
}
.progress-75 {
  stroke: #2AB4DB;
}
.progress-100 {
  stroke: #54A964;
}