.base {
  /* animation: slideIn .4s cubic-bezier(.19,.58,.39,.89) forwards; */
}

@keyframes slideIn {
  0% {
    transform: translate3d(0,-4rem,0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}