.base {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  z-index: 1000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  z-index: 1;
  animation: fadeIn .2s forwards;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.overflow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  overflow-y: scroll;
  /* pointer-events: none; */
}

.modal {
  /* pointer-events: auto; */
  margin: auto;
  padding: 2rem 0;
  position: relative;
  width: 100%;
  z-index: 1;
  animation: slideIn .6s cubic-bezier(.19,.58,.39,.89) forwards;
}
@keyframes slideIn {
  from { transform: translate3d(0,0,0) scale(1.1); opacity: 0; }
  to { transform: translate3d(0,0,0) scale(1); opacity: 1; }
}
@media all and (-ms-high-contrast:none) {
.overflow { align-items: flex-start; } /* IE10 */
.modal { margin: 0; }
*::-ms-backdrop, .overflow { align-items: flex-start; } /* IE11 */
*::-ms-backdrop, .modal { margin: 0; } /* IE11 */
}

.close {
  position: absolute;
  top: 4rem;
  right: 2rem;
  width: 4.6rem;
  height: 4.6rem;
  background-color: #E7343F;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all .2s;
}
.close svg {
  fill: #fff;
}
.close:hover {
  background-color: #BD202A;
}