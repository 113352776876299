  .base {
    display: flex;
    flex-flow: row wrap;
    /* justify-content: space-between; */
    /* margin: 0 -1.6rem; */
  }

  .base > div {
    margin: 1.6rem;
  }

/* ------------------------------------ */

  .base.isAuto > div {
    flex: 1;
  }

/* ------------------------------------ */

  .base.hasMargin-none {
    margin: -1.6rem;
  }

  .base.hasMargin-top-none {
    margin-top: -1.6rem;
  }

/* ------------------------------------ */

  .base.hasGutter-none {
    justify-content: space-between;
    /* margin: 0 1.6rem; */
  }
  .base.hasGutter-none > div {
    margin: 0;
  }

  .base.hasGutter-sm {
    /* margin: 0 -.2rem; */
  }
  .base.hasGutter-sm > div {
    margin: .2rem;
  }

  .base.hasGutter-sm.hasMargin-none {
    margin: -.2rem;
  }
  .base.hasGutter-sm.hasMargin-top-none {
    margin-top: -.2rem;
  }

/* ------------------------------------ */

  .base.hasContainer-md {
    margin: 0 auto;
    max-width: 67rem;
  }

/* ------------------------------------ */

  .base.hasCols-2 {
    justify-content: center;
  }

  .base.hasCols-2 > div {
    flex: 0 1 calc(50% - 3.2rem);
  }

/* ------------------------------------ */

  .base.hasCols-4 {
    justify-content: center;
  }

  .base.hasCols-4 > div {
    flex: 0 1 calc(25% - 3.2rem);
  }

/* ------------------------------------ */

  .base.hasCols-8-4 {
    justify-content: center;
  }

  .base.hasCols-8-4 > div:first-child {
    flex: 0 1 calc(60% - 3.2rem);
  }
  .base.hasCols-8-4 > div:last-child {
    flex: 0 1 calc(40% - 3.2rem);
  }



  .base.hasCols-layout-8-4 {
    flex-flow: row nowrap;
    /* justify-content: center; */
  }

  .base.hasCols-layout-8-4 > div {
    margin: 0;
  }

  .base.hasCols-layout-8-4 > div:first-child {
    width: 100%;
    max-width: 72.8rem;
    /* flex-shrink: 0; */
    /* flex-grow: 0; */
  }
  .base.hasCols-layout-8-4 > div:last-child {
    flex: 1;
  }

/* ------------------------------------ */