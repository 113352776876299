/* ---------------------------------------------------- */

.base {
  position: fixed;
  top: 0;
  left: 0;
  user-select: none;
}

input[type="range"] {
  width: 50rem;
}

/* ---------------------------------------------------- */
