/* ---------------------------------------------- */

  .Dropdown {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    pointer-events: auto;
    user-select: none;
    position: relative;
  }

/* ---------------------------------------------- */

  .Dropdown .Dropdown-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 1rem 1.4rem;
    user-select: none;
    color: #0E165E;
    cursor: pointer;
    transition: background-color .2s;
    height: 5.2rem;
  }
  .Dropdown.has-one-option .Dropdown-select {
    pointer-events: none;
    cursor: default;
  }

  .Dropdown .Dropdown-select:hover {
    background-color: #f4f4f4;
  }

  .Dropdown.is-active .Dropdown-select {
    background-color: #0060DD;
    color: white;
  }

  .Dropdown .Dropdown-select .Dropdown-select-value {
    font-size: 2rem;
    text-align: center;
    flex-grow: 1;
  }
  .Dropdown .Dropdown-select .Dropdown-select-icon {
    flex: 0 0 3rem;
  }

  .Dropdown .Dropdown-select .Dropdown-select-icon svg {
    fill: #9491AA;
  }

  .Dropdown.is-active .Dropdown-select .Dropdown-select-icon svg {
    fill: white;
  }

/* ---------------------------------------------- */

  .Dropdown .Dropdown-content {
    overflow: auto;
    max-height: 56rem;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  }

  .Dropdown .Dropdown-content > div {
    display: block;
    padding: 1rem 1.4rem;
    color: #0E165E;
  }

  .Dropdown .Dropdown-content > div.disabled {
    pointer-events: none;
    opacity: .4;
  }

  .Dropdown .Dropdown-content > div.active,
  .Dropdown .Dropdown-content > div:hover {
    background-color: #DAE8F9;
  }

/* ---------------------------------------------- */
