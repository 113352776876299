/* ---------------------------------------------------- */

.base {
  position: relative;
  overflow: hidden;
  /* padding-bottom: 81.65%; */
}

/* ---------------------------------------------------- */

.base .background img {
  transition: all .6s cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

.base .foreground .mask {
  transition: all .6s cubic-bezier(0.445, 0.050, 0.550, 0.950);
}

.base .foreground .progress .progressValue {
  transition: all .4s;
}

/* ---------------------------------------------------- */

.base .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.base .background img {
  position: absolute;
  top: 0;
  left: 0;
}

.base .foreground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
}

/* ---------------------------------------------------- */

.base .foreground .steps {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  /* pointer-events: auto; */
  animation: fadeIn .4s forwards;
}

.base .foreground .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  /* pointer-events: auto; */
  display: flex;
}

/* ---------------------------------------------------- */

.base .foreground .overlayButton {
  position: absolute;
  right: 2rem;
  top: 2rem;
  pointer-events: auto;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14);
  z-index: 10;
}

.base .foreground .overlayModal {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14);
  width: 50rem;
  text-align: center;
  z-index: 10;
  margin: auto;
  pointer-events: auto;
  animation: fadeIn .4s forwards;
  top:20%;
  left:0;
  right:0;
}

.modalContent {
  margin: 4rem;
  color: #0E165E;
}
.modalContent h1 {
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: bold;
}
.modalContent p {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.modalFooter {
  margin: 4rem;
}

.base .foreground .overlayBackground {
  position: absolute;
  z-index: 1;
  background-color: rgba(0,0,0,.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ---------------------------------------------------- */

.base .foreground .mask {
  position: absolute;
  box-shadow: 0 0 0 999em rgba(0,0,0,.5);
  z-index: 10;
  border-radius: .4rem;
}

/* ---------------------------------------------------- */

.base .foreground .tooltip {
  position: absolute;
  background-color: #fff;
  width: 50rem;
  padding: 0;
  z-index: 15;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14);
  pointer-events: auto;
  animation: fadeIn .4s forwards;
}
.base .foreground .tooltip.has-position-top {
  bottom: calc(100% + 2rem);
  left: 50%;
  transform: translate3d(-50%,0,0);
}
.base .foreground .tooltip.has-position-bottom {
  top: calc(100% + 2rem);
  left: 50%;
  transform: translate3d(-50%,0,0);
}
.base .foreground .tooltip.has-position-right {
  left: calc(100% + 2rem);
  top: 50%;
  transform: translate3d(0,-50%,0);
}
.base .foreground .tooltip.has-position-left {
  right: calc(100% + 2rem);
  top: 50%;
  transform: translate3d(0,-50%,0);
}

.base .foreground .tooltip:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}
.base .foreground .tooltip.has-position-top:after {
  left: 50%;
  bottom: -1.8rem;
  margin-left: -2rem;
  border-left: 2rem solid transparent;
  border-right: 2rem solid transparent;
  border-top: 2rem solid #fff;
}
.base .foreground .tooltip.has-position-bottom:after {
  left: 50%;
  top: -1.8rem;
  margin-left: -2rem;
  border-left: 2rem solid transparent;
  border-right: 2rem solid transparent;
  border-bottom: 2rem solid #fff;
}
.base .foreground .tooltip.has-position-left:after {
  top: 50%;
  right: -1.8rem;
  margin-top: -2rem;
  border-top: 2rem solid transparent;
  border-bottom: 2rem solid transparent;
  border-left: 2rem solid #fff;
}
.base .foreground .tooltip.has-position-right:after {
  top: 50%;
  left: -1.8rem;
  margin-top: -2rem;
  border-top: 2rem solid transparent;
  border-bottom: 2rem solid transparent;
  border-right: 2rem solid #fff;
}

.base .foreground .tooltip .tooltipContent {
  margin: 4rem;
  margin-bottom: 0;
}

.base .foreground .tooltip .tooltipContent h2 {
  font-weight: bold;
  color: #0E165E;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.base .foreground .tooltip .tooltipContent li,
.base .foreground .tooltip .tooltipContent p {
  color: #0E165E;
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.base .foreground .tooltip .tooltipFooter {
  margin: 2rem;
}

/* ---------------------------------------------------- */

.base .foreground .progress {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  right: 3rem;
  z-index: 20;
}
.base .foreground .progress .progressTrack {
  background-color: #fff;
}
.base .foreground .progress .progressValue {
  height: .3rem;
  background-color: #4D4D4D;
}

/* ---------------------------------------------------- */

@keyframes fadeIn { from { opacity: 0; } to { opacity: 100% } }

/* ---------------------------------------------------- */
