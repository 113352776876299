.base {
  border-radius: 1rem;
  background-color: #F6F5F5;
  margin: 3rem 0;
  /* padding: 0 3rem; */
}

.base:first-child {
  margin-top: 0;
}

.base:last-child {
  margin-bottom: 0;
}

.base.hasMargin-none {
  margin: 0;
}

.base.hasMargin-bottom-none {
  margin-bottom: 0;
}

.base.hasMargin-top-none {
  margin-top: 0;
}