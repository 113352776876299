/* font-family: 'Muli', sans-serif; */
/* https://fonts.google.com/specimen/Muli?selection.family=Muli:600,700 */
@import url('https://fonts.googleapis.com/css?family=Muli:600,700&display=swap');

/* font-family: 'Zilla Slab', serif; */
/* https://fonts.google.com/specimen/Zilla+Slab?selection.family=Zilla+Slab:400,700 */
@import url('https://fonts.googleapis.com/css?family=Zilla+Slab:400,700&display=swap');

/* @value color-font: #262626;
@value color-font-version: #A0A0A0;
@value color-background: #FAFAFA;
@value color-background-layer: #F6F5F5;
@value color-accent: #005DA4;
@value color-teaser: #75529D;
@value color-help: #F9D72F;
@value color-error: #E7343F;
@value color-success: #54A964;

@value baseFonTfamily: 'Muli', sans-serif;
@value baseFonTweight: 600;
@value baseFonTsize: 1.5rem;
@value baseFonTcolor: coloRfont;
@value backgroundColor: coloRbackground; */

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  font-family: 'Muli', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #262626;
  background-color: #FAFAFA;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Zilla Slab', serif;
  margin: 1rem 0;
}
/* h1:first-child , h2:first-child , h3:first-child , h4:first-child , h5:first-child , h6:first-child {
  margin-top: 0;
}
h1:last-child , h2:last-child , h3:last-child , h4:last-child , h5:last-child , h6:last-child {
  margin-bottom: 0;
} */

p { margin: 1rem 0; }
/* p:first-child { margin-top: 0; }
p:last-child { margin-bottom: 0; } */

h1 {
  font-size: 3.6rem;
}

h2 {
  font-size: 2.6rem;
}

h3 {
  font-size: 2rem;
}

a,
.link {
  color: #005DA4;
  text-decoration: none;
  cursor: pointer;
}

.text-em {
  color: #005DA4;
  line-height: 2.3rem;
}
.em {
  color: #A0A0A0;
}
.text.size-lg {
  font-size: 1.6rem;
}
.em .bold {
  color: #262626;
  font-size: 1.6rem;
  font-weight: bold;
}
.em.on-dark {
  color: rgba(255,255,255,.5);
}

.hide-scroll {
  overflow: hidden;
}

.el-on-top {
  position: relative;
  z-index: 99999;
}

::selection {
  color: #ffffff;
  background: rgb(0, 93, 164);
}
::-moz-selection {
  color: #ffffff;
  background: rgb(0, 93, 164);
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: rgb(0, 93, 164);
  border-radius: 8px;
  box-shadow: none;
}
::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: none;
}