.base {
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  padding: 1rem 2rem 2rem;
  text-align: center;
  min-width: 33rem;
  position: relative;
  animation: fadeIn .4s forwards;
}

.base.withOverlay {
  /* box-shadow: 0 0 0 999em rgba(0,0,0,.5); */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  z-index: -1;
  animation: fadeIn .4s forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Arrow pointing */

.base:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}

/* Arrow positions */

.base:global(.tooltip-has-position-bottom):after {
  left: 2rem;
  top: -1rem;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid #fff;
}
.base:global(.tooltip-has-position-bottom.tooltip-has-alignment-right):after {
  left: auto;
  right: 2rem;
}
.base:global(.tooltip-has-position-bottom.tooltip-has-alignment-center):after {
  left: 50%;
  right: auto;
  margin-left: -.5rem;
}

.base:global(.tooltip-has-position-top):after {
  left: 2rem;
  bottom: -1rem;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 1rem solid #fff;
}
.base:global(.tooltip-has-position-top.tooltip-has-alignment-right):after {
  left: auto;
  right: 2rem;
}
.base:global(.tooltip-has-position-top.tooltip-has-alignment-center):after {
  left: 50%;
  right: auto;
  margin-left: -.5rem;
}

.base:global(.tooltip-has-position-right):after {
  top: 2rem;
  left: -1rem;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-right: 1rem solid #fff;
}
.base:global(.tooltip-has-position-right.tooltip-has-alignment-center):after {
  top: 50%;
  bottom: auto;
  margin-top: -1rem;
}
.base:global(.tooltip-has-position-right.tooltip-has-alignment-bottom):after {
  top: auto;
  bottom: 2rem;
}

.base:global(.tooltip-has-position-left):after {
  top: 2rem;
  right: -1rem;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-left: 1rem solid #fff;
}
.base:global(.tooltip-has-position-left.tooltip-has-alignment-center):after {
  top: 50%;
  bottom: auto;
  margin-top: -1rem;
}
.base:global(.tooltip-has-position-left.tooltip-has-alignment-bottom):after {
  top: auto;
  bottom: 2rem;
}