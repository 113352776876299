/* ------------------------------------ */

  .base {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
  }

  .back {
    margin-right: 3rem;
  }

  .progress {
    flex: 1;
    height: 4.6rem;
    border-radius: 4.6rem;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    background-color: #fff;
    /* margin: 0 3rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
  }

  .summary {
    margin-left: 3rem;
  }

  .timer {
    display: flex;
    margin-left: 3rem;
  }

/* ------------------------------------ */

  .progressDot {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all .2s;
  }
  .progressDotInner {
    width: 1.2rem;
    height: 1.2rem;
    background-color: #E4E0E6;
    border-radius: 50%;
    transition: all .2s;
  }
  .progressDot:after {
    content: "";
    display: block;
    width: .4rem;
    height: .4rem;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -.2rem 0 0 -.2rem;
    opacity: 0;
    z-index: 1;
    transition: all .2s;
  }

  .progressDot.isActive .progressDotInner {
    background-color: #005DA4;
  }
  .progressDot.isActive:after {
    opacity: 1;
  }

  .progressDot.isCorrect,
  .progressDot.isCorrect .progressDotInner {
    background-color: transparent;
  }
  .progressDot.isCorrect svg {
    fill: #54A964;
  }
  /* .progressDot.isCorrect:after {
    opacity: 1;
  } */

  .progressDot.isWrong,
  .progressDot.isWrong .progressDotInner {
    background-color: transparent;
  }
  .progressDot.isWrong svg {
    fill: #E7343F;
  }
  /* .progressDot.isWrong:after {
    opacity: 1;
  } */

  .progressDot.isActive.isCorrect,
  .progressDot.isActive.isWrong {
    background-color: #005DA4;
  }
  .progressDot.isActive.isCorrect:after,
  .progressDot.isActive.isWrong:after {
    content: none;
  }
  .progressDot.isActive.isCorrect svg,
  .progressDot.isActive.isWrong svg {
    fill: #fff;
  }

/* ------------------------------------ */

  .timerIcon {
    margin-right: 1rem;
  }
  .timerIcon svg {
    fill: #A0A0A0;
  }

  .timerLabel {
    font-weight: bold;
    font-size: 1.6rem;
  }

  .timer.isOver .timerIcon svg {
    fill: #e7343f;
  }

/* ------------------------------------ */