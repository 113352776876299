.course-progress {
  width: 8rem;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  margin: 2rem;
  font-size: 1.4rem;
  opacity: .3;
  transition: all .2s;
}
.course-progress-active {
  opacity: 1;
}

.course-progress-icon {
  position: relative;
}

.course-progress-icon-top {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
}

.course-progress-icon-top path {
  fill: #fff;
}

.course-progress-icon-bottom {

}

.course-progress-label {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-misc,
.progress-blob {
  fill: rgba(255,255,255,.3);
}
.progress-circle {
  fill: rgba(255,255,255,.1);
}

.progress-icon {
  fill: #fff;
}

.progress-blob {
  animation-name: rotateAnim;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 10s;
}

.progress-wave-clip {
  mask: url(#progress-wave-mask);
}

.progress-wave-group {
  /* animation-name: progressAnim;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.2, .6, .8, .4);
  animation-duration: 4s;
  animation-fill-mode: alternate; */
  transform: translate3d(0,100%,0);
  transition: all 4s cubic-bezier(.2, .6, .8, .4);
}
.progress-wave-path {
  animation-name: waveAnim;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 1s;
}
@keyframes progressAnim {
  0% {
    transform: translate3d(0,100%,0);
  }
  100% {
    transform: translate3d(0,-5%,0);
  }
}
@keyframes waveAnim {
  0% {
    transform: translate3d(-70%,0,0);
  }
  100% {
    transform: translate3d(0,0,0);
  }
}
@keyframes rotateAnim {
  0% {
    transform: rotate(360deg);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(0);
    transform-origin: 50% 50%;
  }
}