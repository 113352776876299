.base {
  margin: 2rem 0;
  text-align: left;
  color: #005DA4;
}

.base > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.base > ul > li {
  border-bottom: .1rem solid #F6F5F5;
  padding: 1.2rem 0 1.2rem 1.2rem;
}

.base > ul > li:last-child {
  border-bottom: 0;
}

.base ul > li:global(.highlighted) {
  color: #54A964;
  background-color: #DDEEE0;
  border-radius: .4rem;
  border-bottom-color: #fff;
}