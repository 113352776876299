.base,
.layer,
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.base {
  z-index: 100;
  pointer-events: none;
}

.layer {
  z-index: 10;
  pointer-events: auto;
}

.overlay {
  z-index: 1;
  background-color: rgba(0,0,0,.6);
  animation: fadeIn .4s forwards;
}

.trigger {
  pointer-events: auto;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 100;
}

:global(.tour-plugin-mask) {
  position: relative;
  pointer-events: none;
  animation: comeIn .4s forwards;
}

@keyframes comeIn {
  from { z-index: 1; }
  to { z-index: 99999; }
}

.mask {
  position: absolute;
  z-index: 1;
  pointer-events: auto;
  width: 0px;
  height: 0px;
  transform-origin: 50% 50%;

  border-radius: 1rem;
  transition: .6s;
  box-shadow: 0 0 0 999em rgba(0,0,0,.5);
  animation: fadeIn .4s forwards;

  /* background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.1); */
}

.tooltip {
  position: absolute;
  z-index: 30;
  pointer-events: auto;
  transition: .6s;
  transform-origin: 0 0;
  width: 34rem;
  animation: fadeIn .4s .2s forwards;
  opacity: 0;
}

.tooltipContent {
  background-color: #fff;
  padding: 2rem;
  border-radius: .5rem;
  position: relative;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes zoomIn {
  from { opacity: 0; transform: scale(.5); }
  to { opacity: 1; transform: scale(1); }
}


/* Arrow pointing */

.tooltipContent:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}

/* Arrow positions */

.tooltipContent:global(.tooltip-has-position-bottom):after {
  left: 2rem;
  top: -1rem;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid #fff;
}
.tooltipContent:global(.tooltip-has-position-bottom.tooltip-has-alignment-right):after {
  left: auto;
  right: 2rem;
}
.tooltipContent:global(.tooltip-has-position-bottom.tooltip-has-alignment-center):after {
  left: 50%;
  right: auto;
  margin-left: -.5rem;
}

.tooltipContent:global(.tooltip-has-position-top):after {
  left: 2rem;
  bottom: -1rem;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 1rem solid #fff;
}
.tooltipContent:global(.tooltip-has-position-top.tooltip-has-alignment-right):after {
  left: auto;
  right: 2rem;
}
.tooltipContent:global(.tooltip-has-position-top.tooltip-has-alignment-center):after {
  left: 50%;
  right: auto;
  margin-left: -.5rem;
}

.tooltipContent:global(.tooltip-has-position-right):after {
  top: 2rem;
  left: -1rem;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-right: 1rem solid #fff;
}
.tooltipContent:global(.tooltip-has-position-right.tooltip-has-alignment-center):after {
  top: 50%;
  bottom: auto;
  margin-top: -1rem;
}
.tooltipContent:global(.tooltip-has-position-right.tooltip-has-alignment-bottom):after {
  top: auto;
  bottom: 2rem;
}

.tooltipContent:global(.tooltip-has-position-left):after {
  top: 2rem;
  right: -1rem;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-left: 1rem solid #fff;
}
.tooltipContent:global(.tooltip-has-position-left.tooltip-has-alignment-center):after {
  top: 50%;
  bottom: auto;
  margin-top: -1rem;
}
.tooltipContent:global(.tooltip-has-position-left.tooltip-has-alignment-bottom):after {
  top: auto;
  bottom: 2rem;
}