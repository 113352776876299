.background {
  position: absolute;
  top: -74rem;
  left: -72rem;
  width: 100%;
  height: 100%;
}

.background svg {
  width: 157.3rem;
  height: 162.7rem;
}

.content {
  position: relative;
  z-index: 1;
  text-align: left;
}

.header {
  display: flex;
  margin-bottom: 2rem;
}

.header h1 {
  color: #fff;
  margin: 0 0 0 2rem;
}

.navigation {
  display: flex;
  margin: 0 -.1rem;
}

.navigation > a {
  flex: 1 1 25%;
  background-color: #fff;
  padding: 2.4rem 2rem;
  color: #005DA4;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  margin: 0 .1rem;
  cursor: pointer;
  transition: all .2s;
  text-align: center;
}
.navigation > a:hover,
.navigation > a:global(.active) {
  background-color: #2AB4DB;
  color: #fff;
}

.navigation > a:first-child {
  border-radius: 1rem 0 0 1rem;
}

.navigation > a:last-child {
  border-radius: 0 1rem 1rem 0;
}

.tab {
  margin-top: 2rem;
  background-color: #fff;
  border-radius: 1rem;
}