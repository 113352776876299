/* ----------------------------------------------------------- */

  .checkbox {
    position: relative;
  }

  .checkbox label {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 140%;

    box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
    padding: 1rem;
    transition: all .2s;
  }

/* ----------------------------------------------------------- */

  .checkbox .input {
    position: relative;
  }

  .checkbox .input input {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  .checkbox .input:before {
    content: "";
    margin-top: .1rem;
    width: 2.2rem;
    height: 2.2rem;
    display: block;
    background-color: #fff;
    border: .1rem solid #9491AA;
  }

  .checkbox .input:after {
    content: "";
    width: 1.2rem;
    height: 1.2rem;
    display: block;
    background-color: #0060DD;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -.6rem 0 0 -.6rem;
    opacity: 0;
    transform: scale(0.1);
    transition: all 0.2s;
  }

  .checkbox:not(.isActive):not(.isSelected):not(.isCorrect):not(.isWrong):not(.isMissing) label:hover .input:after {
    opacity: 1;
    transform: scale(1);
    background-color: #9491AA;
  }

/* ----------------------------------------------------------- */

  .checkbox .label {
    margin-left: 1rem;
  }

  /* ----------------------------------------------------------- */

  .checkbox.isActive label {
    background-color: #0060DD;
    color: #fff;
    box-shadow: none;
  }

  .checkbox.isActive .input:before {
    background-color: #0060DD;
    border-color: #fff;
  }

  .checkbox.isActive .input:after {
    opacity: 1;
    transform: scale(1);
    background-color: #fff;
  }

/* ----------------------------------------------------------- */

  .checkbox.isSelected label {
    background-color: #0060DD;
    color: #fff;
    box-shadow: none;
  }

  .checkbox.isSelected .input:before {
    background-color: #0060DD;
    border-color: #fff;
  }

  .checkbox.isSelected .input:after {
    opacity: 1;
    transform: scale(1);
    background-color: #fff;
  }

/* ----------------------------------------------------------- */

  .checkbox.isCorrect label {
    background-color: #E6F3E6;
    color: #128A0B;
    font-weight: bold;
    font-size: 1.6rem;
    box-shadow: none;
  }

  .checkbox.isCorrect .input:before {
    background-color: transparent;
    border-color: #128A0B;
  }

  .checkbox.isCorrect .input:after {
    opacity: 1;
    transform: scale(1);
    background-color: #128A0B;
  }

/* ----------------------------------------------------------- */

  .checkbox.isWrong label {
    background-color: #F7E2E4;
    color: #D0021B;
    font-weight: bold;
    font-size: 1.6rem;
    box-shadow: none;
  }

  .checkbox.isWrong .input:before {
    background-color: transparent;
    border-color: #D0021B;
  }

  .checkbox.isWrong .input:after {
    opacity: 1;
    transform: scale(1);
    background-color: #D0021B;
  }

/* ----------------------------------------------------------- */

  .checkbox.isMissing label {
    background-color: #fff;
    color: #128A0B;
    box-shadow: inset 0 0 0 .2rem #128A0B;
    font-weight: bold;
    font-size: 1.6rem;
  }

  .checkbox.isMissing .input:before {
    background-color: transparent;
    border-color: #128A0B;
  }

/* ----------------------------------------------------------- */
