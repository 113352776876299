.base {
  display: flex;
  /* margin: 0 -1.6rem; */
  justify-content: center;
  flex-flow: row wrap;
}

.base > div {
  flex: 1 1 25%;
  margin: 1.6rem 0;
}

.progress {
  position: relative;
  display: inline-block;
}

.action {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #2AB4DB;
  color: #fff;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  z-index: 1;
  transition: all .2s;
}
.action svg {
  fill: #fff;
}
.action:hover {
  background-color: #008CB3;
}

.action.isInverted {
  background-color: #fff;
}
.action.isInverted svg {
  fill: #2AB4DB;
}
.action.isInverted:hover {
  background-color: #2AB4DB;
}
.action.isInverted:hover svg {
  fill: #fff;
}

.title {
  color: #005DA4;
  line-height: 2.3rem;
  margin-top: 1.2rem;
}