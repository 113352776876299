.base {
  
}

.base.isSize-md {
  margin-left: auto;
  margin-right: auto;
  max-width: 70rem;
}

.base.isSize-sm {
  margin-left: auto;
  margin-right: auto;
  max-width: 49.6rem;
}

.base.isSize-xs {
  margin-left: auto;
  margin-right: auto;
  max-width: 32rem;
}

.base.hasPadding-none {
  padding: 0;
}

.base.hasPadding-md {
  padding: 3rem;
}

.base.hasPadding-vertical-md {
  padding: 3rem 0;
}

.base.hasPadding-sm {
  padding: 2rem;
}

.base.hasPadding-vertical-sm {
  padding: 2rem 0;
}


.base.hasMargin-md {
  margin-top: 3rem;
  margin-bottom: 3rem;
}