.background {
  position: absolute;
  top: -74rem;
  left: -72rem;
  width: 100%;
  height: 100%;
}

.background svg {
  width: 157.3rem;
  height: 162.7rem;
}

.content {
  position: relative;
  z-index: 1;
  color: #fff;
}

.header {
  color: #fff;
  max-width: 37rem;
  margin: 2rem auto 0;
}

.header :global(.em) {
  color: #fff;
}