/* ---------------------------------------- */

  .base {
    /* padding: 2rem 0; */
  }

  .field {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: left;
  }

  .field.isDisabled {
    pointer-events: none;
  }

/* ---------------------------------------- */

  .fieldLabel {
    margin-bottom: 1rem;
  }
  .fieldLabel label {
    font-weight: bold;
    font-size: 1.067em;
  }

  .field.isDisabled .fieldLabel label {
    color: #A0A0A0;
  }

  .fieldComponent {
    position: relative;
  }

  .fieldButton {
    position: absolute;
    top: .7rem;
    right: .7rem;
  }

  .hasButton .input input {
    padding-right: 6rem;
  }

/* ---------------------------------------- */

  .input input,
  .select select,
  .textarea textarea {
    border: .1rem solid #D0D0D0;
    background-color: #fff;
    height: 6rem;
    border-radius: .8rem;
    padding: 2rem;
    color: #005DA4;
    outline: none;
    transition: all .2s;
    width: 100%;
    display: block;
  }
  .input input:focus,
  .select select:focus,
  .textarea textarea:focus {
    border-color: #005DA4;
  }

  .input input::-webkit-input-placeholder,
  .textarea textarea::-webkit-input-placeholder {
    color: #A0A0A0;
  }
  .input input::-moz-placeholder,
  .textarea textarea::-moz-placeholder {
    color: #A0A0A0;
  }
  .input input:-ms-input-placeholder,
  .textarea textarea:-ms-input-placeholder {
    color: #A0A0A0;
  }

/* ---------------------------------------- */

  .input {
    
  }
  .input input {
    
  }

/* ---------------------------------------- */
 
  .select {

  }
  .select select {
    border-radius: 6rem;
  }

/* ---------------------------------------- */

  .dropdown {
    user-select: none;
    position: relative;
    /* min-width: 8rem; */
    text-align: left;
    /* width: 100%; */
  }

  .dropdownValue {
    background-color: #fff;
    padding: 1.4rem 1.6rem;
    border-radius: 3rem;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    cursor: pointer;
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdownValue > div:first-child {
    margin-right: 1rem;
  }

  .dropdownValue svg {
    fill: #2AB4DB;
  }

  .dropdownList {
    position: absolute;
    width: 100%;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    background-color: #fff;
    border-radius: 0 0 .4rem .4rem;
    animation: dropdownList .2s forwards;
  }

  .dropdownList > div {
    padding: .8rem 1.6rem;
    color: #005DA4;
    cursor: pointer;
    transition: .2s;
  }
  .dropdownList > div:hover {
    background-color: #F6F5F5;
    /* color: #fff; */
  }

  @keyframes dropdownList {
    from {
      opacity: 0;
      transform: translate3d(0,-20%,0);
    }
    to {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }

  .dropdown.isOpen .dropdownValue {
    border-radius: .4rem .4rem 0 0;
  }

  .dropdown.isLarge .dropdownValue {
    height: 6rem;
    padding: 0 2rem;
  }

  .dropdown.isLarge .dropdownList > div {
    padding: 1.6rem 2rem;
  }


/* ---------------------------------------- */

  .textarea {

  }
  .textarea textarea {

  }

/* ---------------------------------------- */

  .toggle {
    width: 6rem;
    height: 3rem;
    background-color: #E4E4E4;
    border-radius: 3rem;
    position: relative;
    cursor: pointer;
    transition: all .2s;
  }
  .toggle:before {
    content: "";
    width: 2.8rem;
    height: 2.8rem;
    display: block;
    background-color: #A0A0A0;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    margin: .1rem;
    transition: all .2s;
    transform: translate3d(0,0,0);
  }

  .toggle.isActive {
    background-color: #2AB4DB;
  }

  .toggle.isActive:before {
    background-color: #fff;
    transform: translate3d(3rem,0,0);
  }

/* ---------------------------------------- */

  .base.isInline .field {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    min-height: 3rem;
  }

  .base.isInline .fieldLabel {
    margin: 0;
  }

  .base.isInline :global(.is-toggle) .fieldLabel {
    order: 2;
    margin: 0 0 0 1.6rem;
  }

  .base.isInline .fieldComponent {
    order: 1;
  }

/* ---------------------------------------- */