/* ----------------------------------------------------------- */

  .base {
    margin: -1rem 0 2rem 0;
    color: #005DA4;
    text-align: left;
    counter-reset: list;
    animation: slideIn .4s cubic-bezier(.19,.58,.39,.89) forwards;
  }
  @keyframes slideIn {
    0% {
      /* opacity: 0; */
      transform: translate3d(0,-2rem,0);
    }
    100% {
      /* opacity: 1; */
      transform: translate3d(0,0,0);
    }
  }

  .row {
    margin: 1rem 0;
  }

/* ----------------------------------------------------------- */

  .checkbox label,
  .radio label {
    background-color: #fff;
    border-radius: .8rem;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    line-height: 140%;
    padding: 1rem 2rem;
  }
  .checkbox label:hover,
  .radio label:hover {
    transform: scale(1.02);
  }

  .checkbox .input:before,
  .radio .input:before {
    margin-top: 0;
    width: 4.2rem;
    height: 4.2rem;
    border: .2rem solid #F6F5F5;
    border-radius: 50%;
  }

  .checkbox .input:after,
  .radio .input:after {
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: transparent;
    transform: scale(1);
    transition: background-color .2s;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    counter-increment: list;
    content: counter(list, upper-latin);
    font-weight: bold;
  }

  .checkbox:not(.isActive):not(.isSelected):not(.isCorrect):not(.isWrong):not(.isMissing) label:hover .input:after,
  .radio:not(.isActive):not(.isSelected):not(.isCorrect):not(.isWrong):not(.isMissing) label:hover .input:after {
    opacity: 1;
    transform: scale(1);
    background-color: #005DA4;
    color: #fff;
  }

/* ----------------------------------------------------------- */

  .checkbox.isActive label,
  .checkbox.isSelected label,
  .radio.isActive label,
  .radio.isSelected label {
    background-color: #005DA4;
    color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    transform: scale(1);
  }

  .checkbox.isActive .input:before,
  .checkbox.isSelected .input:before,
  .radio.isActive .input:before,
  .radio.isSelected .input:before {
    background-color: transparent;
    border-color: transparent;
  }

  .checkbox.isActive .input:after,
  .checkbox.isSelected .input:after,
  .radio.isActive .input:after,
  .radio.isSelected .input:after {
    opacity: 1;
    transform: scale(1);
    background-color: #023d6a;
    color: #fff;
  }

/* ----------------------------------------------------------- */

  .checkbox.isCorrect label,
  .radio.isCorrect label {
    background-color: #54A964;
    color: #fff;
    font-weight: inherit;
    font-size: inherit;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  }

  .checkbox.isCorrect .input:before,
  .radio.isCorrect .input:before {
    background-color: transparent;
    border-color: transparent;
  }

  .checkbox.isCorrect .input:after,
  .radio.isCorrect .input:after {
    opacity: 1;
    transform: scale(1);
    background-color: #3C6744;
  }

/* ----------------------------------------------------------- */

  .checkbox.isWrong label,
  .radio.isWrong label {
    background-color: #E7343F;
    color: #fff;
    font-weight: inherit;
    font-size: inherit;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  }

  .checkbox.isWrong .input:before,
  .radio.isWrong .input:before {
    background-color: transparent;
    border-color: transparent;
  }

  .checkbox.isWrong .input:after,
  .radio.isWrong .input:after {
    opacity: 1;
    transform: scale(1);
    background-color: #862C32;
  }

/* ----------------------------------------------------------- */

  .checkbox.isMissing label,
  .radio.isMissing label {
    background-color: #fff;
    color: inherit;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    font-weight: inherit;
    font-size: inherit;
  }

  .checkbox.isMissing .input:before,
  .radio.isMissing .input:before {
    background-color: transparent;
    border-color: transparent;
  }

  .checkbox.isMissing .input:after,
  .radio.isMissing .input:after {
    opacity: 1;
    transform: scale(1);
    background-color: #A9D3B1;
  }

/* ----------------------------------------------------------- */