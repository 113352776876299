.base {
  color: #A0A0A0;
  position: relative;
  text-align: center;
  margin: 1.6rem 0;
}

.base > span {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 2rem;
}

.base:before {
  content: "";
  border-top: .1rem solid #F6F5F5;
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
}