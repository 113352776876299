.base {
  text-align: left;
  margin: 1.6rem 0;
}

.title {
  margin-bottom: 1.6rem;
  color: #005DA4;
}

.list {
  text-align: center;
}