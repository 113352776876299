.base {
  position: absolute;
  top: 50%;
  left: 50%;
}

.bubble {
  width: 2.2rem;
  height: 2.2rem;
  background-color: #fff;
  border-radius: 50%;
  transition: .2s;
  cursor: pointer;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}
.bubble:hover {
  transform: scale(1.2);
}
.bubble:active {
  transform: scale(.6);
}

.bubble:after {
  content: "";
  display: block;
  width: .8rem;
  height: .8rem;
  background-color: #262626;
  border-radius: 50%;
  transition: .2s;
}
.bubble:hover:after {
  transform: scale(1.4);
  background-color: #2AB4DB;
}

.bubble:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: -.5rem 0 0 -.5rem;
  pointer-events: none;
  z-index: -1;
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  background-color: #fff;
  border-radius: 50%;
  animation: focus 1s infinite;
}

@keyframes focus {
  from {
    transform: scale(.5);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}