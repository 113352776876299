.base {
  color: #fff;
  text-align: center;
  width: 100%;
}

.title {
  margin-top: 0;
}

.ctas {
  padding: 0 2rem;
}

.courses {
  margin-top: 2rem;
}

.center {
  margin-top: 3rem;
  min-height: 6.5rem;
}

.center :global(.button-el) {
  max-width: 25rem;
}

.bottom {
  margin-top: 6rem;
  color: #005da4;
}

.examFilelistContainer {
  margin-top: 1rem;
}

.examFilelistContainer a {
  display:block;
  padding: 1rem;
  width: 100%;
}
