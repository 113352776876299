/* ------------------------------------ */

  .base {
    background-color: #005DA4;
    border-color: transparent;
    border-radius: 4rem;
    font-size: 1.6rem;
    padding: 1.1rem 3rem 1.3rem;
    border-width: .2rem;
  }

  .base:hover {
    background-color: #004B83;
    border-color: transparent;
  }


/* ------------------------------------ */

  .base.isLarge {
    font-size: 1.8rem;
    padding-top: 1.7rem;
    padding-bottom: 1.9rem;
  }

/* ------------------------------------ */

  .base.isRound {
    padding: 0;
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 50%;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #fff;
    transition: all .2s;
    cursor: pointer;
    margin-right: 3rem; */
  }
  .base.isRound .icon {
    margin: 0;
  }

/* ------------------------------------ */

  .base.isRounded {
    border-radius: .5rem;
  }

/* ------------------------------------ */

  .base.isPrimary {
    background-color: #F9D72F;
    border-color: transparent;
    color: #262626;
  }

  .base.isPrimary:hover {
    background-color: #EEBD00;
    border-color: transparent;
  }

  .base.isPrimary .icon svg {
    fill: #262626;
  }

/* ------------------------------------ */

  .base.isSecondary {
    background-color: #2AB4DB;
    border-color: transparent;
    color: #fff;
  }

  .base.isSecondary:hover {
    background-color: #008CB3;
    border-color: transparent;
  }

/* ------------------------------------ */

.base.isTeaser {
  background-color: transparent;
  border-color: #75529D;
  color: #75529D;
}

.base.isTeaser:hover {
  background-color: #75529D;
  border-color: transparent;
  color: #fff;
}

/* ------------------------------------ */

  .base.isSuccess {
    background-color: #54A964;
    border-color: transparent;
  }

  .base.isSuccess:hover {
    background-color: #3C924D;
    border-color: transparent;
  }

  .base.isSuccess.isOutlined {
    background-color: transparent;
    border-color: #54A964;
    color: #54A964;
  }
  .base.isSuccess.isOutlined:hover {
    background-color: #54A964;
    border-color: transparent;
    color: #fff;
  }

/* ------------------------------------ */

  .base.isGray {
    background-color: #F6F5F5;
    border-color: transparent;
    color: #262626;
  }

  .base.isGray:hover {
    background-color: #A0A0A0;
    border-color: transparent;
    color: #fff;
  }

  .base.isGray.withError:hover {
    background-color: #BD202A;
  }

/* ------------------------------------ */

  .base.isError {
    background-color: #E7343F;
    border-color: transparent;
  }

  .base.isError:hover {
    background-color: #BD202A;
    border-color: transparent;
  }

/* ------------------------------------ */

  .base.isWhite {
    background-color: #fff;
    border-color: transparent;
    color: #005DA4;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  }

  .base.isWhite:hover {
    background-color: #2AB4DB;
    border-color: transparent;
    color: #fff;
  }

  .base.isWhite .icon svg {
    fill: #005DA4;
  }

  .base.isWhite.isOutlined {
    border-color: #fff;
    background-color: transparent;
    color: #fff;
  }

  .base.isWhite.isOutlined .icon svg {
    fill: #fff;
  }

  .base.isWhite.isOutlined:hover {
    border-color: transparent;
    background-color: #2AB4DB;
    color: #fff;
  }

  .base.isWhite.withError {
    color: #E7343F;
  }
  .base.isWhite.withError .icon svg {
    fill: #E7343F;
  }

  .base.isWhite.withSuccess {
    color: #54A964;
  }
  .base.isWhite.withSuccess .icon svg {
    fill: #54A964;
  }

  .base.isWhite:hover {
    color: #fff;
  }
  .base.isWhite:hover .icon svg {
    fill: #fff;
  }

  .base.isWhite.isProduct {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .base.isWhite.isProduct > div {
    justify-content: space-between;
  }

  .base.isWhite.isProduct .label {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .base.isWhite.isProduct .icon {
    margin-left: 0;
  }

  .base.isWhite.isProduct .icon svg {
    fill: #54A964;
  }

  .base.isWhite.isProduct .info {
    color: #54A964;
    font-weight: normal;
    padding-right: 1rem;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .base.isWhite.isProduct:hover {
    background-color: #F9D72F;
    border-color: transparent;
    color: #262626;
  }

  .base.isWhite.isProduct:hover .info {
    color: #262626;
  }

  .base.isWhite.isProduct:hover .icon svg {
    fill: #262626;
  }

/* ------------------------------------ */

  .base.isDisabled {
    background-color: #F6F5F5;
    border-color: transparent;
    color: #A0A0A0;
    opacity: 1;
  }

  .base.isDisabled .icon svg {
    fill: #A0A0A0;
  }
  
/* ------------------------------------ */

  .base.isDark {
    background-color: #262626;
    color: #fff;
  }

/* ------------------------------------ */

  .group.isAuto {
    margin: 0 -1.6rem;
  }
  .group.isAuto:first-child {
    margin-top: -1.6rem;
  }
  .group.isAuto:last-child {
    margin-bottom: -1.6rem;
  }

  .group.isAuto > div {
    flex: 1 1 calc(50% - 3.2rem);
    margin: 1.6rem;
  }

  .group.isVertical {
    flex-direction: column;
    margin: 0;
  }

  .group.isVertical .base {
    margin: .5rem 0;
  }

/* ------------------------------------ */

  .base.isHidden {
    visibility: hidden;
  }

/* ------------------------------------ */