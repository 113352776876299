.base {
  position: relative;
  max-width: 14.2rem;
  min-width: 10rem;
  user-select: none;
  margin: 0 auto;
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text > span {
  display: flex;
  align-items: flex-start;
}

.textLabel {
  font-size: 2.6rem;
  color: #005DA4;
  line-height: 100%;
}
.textPercent {
  font-size: 1.4rem;
  color: #A0A0A0;
}

.progress {

}