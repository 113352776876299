.enter,
.appear {
  opacity: 0;
  transform: translate3d(0,100%,0);
  will-change: transform;
  pointer-events: auto;
}

.enterActive,
.appearActive {
  opacity: 1;
  transform: translate3d(0,0,0);
  transition: opacity .6s cubic-bezier(.19,.58,.39,.89), transform .6s cubic-bezier(.19,.58,.39,.89);
  /* pointer-events: none; */
}

.exit {
  opacity: 1;
  transform: translate3d(0,0,0);
}

.exitActive {
  opacity: 0;
  transform: translate3d(0,100%,0);
  transition: opacity .6s cubic-bezier(.19,.58,.39,.89), transform .6s cubic-bezier(.19,.58,.39,.89);
  pointer-events: none;
}