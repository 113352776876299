.enter,
.appear {
  opacity: 0;
  transform: scale(0.5) translateY(-50%);
  will-change: transform;
  pointer-events: auto;
}

.enterActive,
.appearActive {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 2s cubic-bezier(.19,.58,.39,.89), transform 2s cubic-bezier(.19,.58,.39,.89);
  /* pointer-events: none; */
}

.exit {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.exitActive {
  opacity: 0;
  transform: scale(0.5) translateY(50%);
  transition: opacity 2s cubic-bezier(.19,.58,.39,.89), transform 2s cubic-bezier(.19,.58,.39,.89);
  pointer-events: none;
}