.base {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #54A964;
  background-image: url(./blob-01.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 100%;
  z-index: 10;
}

.base.isWrong {
  background-color: #E7343F;
}

.inner {
  padding: 3rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message {
  flex: 1;
}

.actions {
  flex-shrink: 0;
}
.actions:first-child {
  margin-right: 4rem;
}
.actions:last-child {
  margin-left: 4rem;
}

.inner a {
  color: #fff;
}

.inner h3 {
  /* margin: 0; */
}

.inner p {
  line-height: 2.7rem;
}