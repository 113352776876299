/* ---------------------------------------- */

  .base {
    /* max-width: 35.2rem; */
  }

  .mask {
    overflow: hidden;
  }
  .base.hasScroll .mask {
    overflow-x: auto;
    padding: .2rem 0;
  }

  .slides {
    display: flex;
    flex-flow: row nowrap;
    transition: all .2s;
  }

  .slide {
    /* Set automatically from js */
    /* flex: 1 0 33.33%; */
  }
  .base.hasScroll .slide {
    /* max-width: 15.6rem; */
  }

  .slide.isTrigger {
    cursor: pointer;
    transition: all .2s;
  }
  .slide.isTrigger:hover {
    opacity: .9;
  }

  .slide > div {
    padding-bottom: 100%;
    position: relative;
    background-color: #efefef;
    border-radius: .8rem;
    margin: .2rem;
    overflow: hidden;
  }
  .slide:first-child > div {
    margin-left: 0;
  }
  .slide:last-child > div {
    margin-right: 0;
  }

  .slideInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

/* ---------------------------------------- */

  .mask::-webkit-scrollbar {
    height: 4px;
  }

  .mask::-webkit-scrollbar-track {
    background: #D8D8D8;
    border-radius: 4px;
  }

  .mask::-webkit-scrollbar-thumb {
    background: #005DA4;
    border-radius: 4px;
  }

  .mask::-webkit-scrollbar-thumb:hover {
    background: #004B83;
  }

/* ---------------------------------------- */
